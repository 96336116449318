import { Injectable } from '@angular/core';

import { ListRequest, ListResponse } from 'src/models';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class GenericObjectsService<T> {
  protected baseUrl: string;

  constructor(protected apiService: ApiService) {}

  list(options: ListRequest = {}): Promise<ListResponse<Partial<T>>> {
    return this.apiService.postPromise(`${this.baseUrl}/list`, options);
  }

  listAll(): Promise<Partial<T>[]> {
    return this.apiService.getPromise(`${this.baseUrl}`);
  }

  getById(id: string): Promise<T> {
    return this.apiService.getPromise(`${this.baseUrl}/${id}`);
  }

  create(payload: Partial<T>): Promise<T> {
    return this.apiService.postPromise(`${this.baseUrl}`, payload);
  }

  updatePartial(id: string, payload: Partial<T>): Promise<T> {
    return this.apiService.patchPromise(`${this.baseUrl}/${id}`, payload);
  }

  update(id: string, payload: Partial<T>): Promise<T> {
    return this.apiService.putPromise(`${this.baseUrl}/${id}`, payload);
  }

  delete(id: string): Promise<{ success: boolean }> {
    return this.apiService.deletePromise(`${this.baseUrl}/${id}`);
  }
}
